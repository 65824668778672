<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="big-title">系统设置</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-suffix="：" label-width="180px" class="demo-ruleForm">
                <el-form-item label="网站应用名称" prop="web_application">
                    <el-input v-model="ruleForm.web_application" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="公众号appid" prop="wx_public_appid">
                    <el-input v-model="ruleForm.wx_public_appid" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="公众号secret" prop="wx_public_secret">
                    <el-input v-model="ruleForm.wx_public_secret" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="小程序appid" prop="wx_small_program_appid">
                    <el-input v-model="ruleForm.wx_small_program_appid" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="小程序secret" prop="wx_small_program_secret">
                    <el-input v-model="ruleForm.wx_small_program_secret" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item label="模板发送者open_id" prop="gh_openid">
                    <el-input v-model="ruleForm.gh_openid" style="width: 400px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </el-scrollbar>
    </div>
</template>

<script>
    import { settingIndex, settingUpdate } from '@/config/apis.js'
    export default {
        name: "Index",
        data() {
            return {
                ruleForm: {
                    web_application: '',
                    wx_public_appid: '',
                    wx_public_secret: '',
                    wx_small_program_appid: '',
                    wx_small_program_secret: '',
                    gh_openid: '',
                },
                rules: {
                    web_application: [
                        { required: true, message: '请输入网站应用名称', trigger: 'blur' },
                    ],
                    wx_public_appid: [
                        { required: true, message: '请输入公众号appid', trigger: 'blur' },
                    ],
                    wx_public_secret: [
                        { required: true, message: '请输入公众号secret', trigger: 'blur' },
                    ],
                    wx_small_program_appid: [
                        { required: true, message: '请输入小程序appid', trigger: 'blur' },
                    ],
                    wx_small_program_secret: [
                        { required: true, message: '请输入小程序secret', trigger: 'blur' },
                    ],
                    gh_openid: [
                        { required: true, message: '请输入模板发送者open_id', trigger: 'blur' },
                    ],
                }

            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                settingIndex().then(res => {
                    this.ruleForm = res.data
                }).catch(err => {})
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('web_application', this.ruleForm.web_application)
                        formData.append('wx_public_appid', this.ruleForm.wx_public_appid)
                        formData.append('wx_public_secret', this.ruleForm.wx_public_secret)
                        formData.append('wx_small_program_appid', this.ruleForm.wx_small_program_appid)
                        formData.append('wx_small_program_secret', this.ruleForm.wx_small_program_secret)
                        formData.append('gh_openid', this.ruleForm.gh_openid)

                        settingUpdate(formData).then(res => {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1000,
                                onClose: () => {
                                    this.getInfo()
                                    location.reload()
                                }
                            });
                        }).catch(err => {})
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .big-title {
        margin: 20px 20px 0;
        font-size: 20px;
        color: #000;
        font-weight: bold;
        line-height: 1;
    }
    .demo-ruleForm {
        margin: 20px;
    }
</style>